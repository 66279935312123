import {SearchDriverOptions, RequestState} from '@elastic/search-ui';

export type MinervaSearchTermString = {
  type: 'string';
  term: string;
};

export type MinervaSearchQuery = {
  searchTerms: MinervaSearchTermString[];
};

export type MinervaSearchGroup = {};

export type PatientSortOption =
  | 'Exam date ASC'
  | 'Exam date DESC'
  | 'Body Part'
  | 'Modality';

export type ExtendedSearchDriverOptions = SearchDriverOptions & {
  searchQuery: {
    groupPatientIDs: boolean;
    queryBuilderQuery?: Object;
    sortPatientIDsBy: PatientSortOption;
    useVectorSearch: boolean;
  };
  appSearchEndpoint: string;
};

export const fetchMatchingStudyIds = ({
  config,
  requestState,
  queryConfig,
  numStudies = 5000,
}: {
  config: ExtendedSearchDriverOptions;
  requestState: RequestState;
  queryConfig: Object;
  numStudies?: number;
}) => {
  // need to take the current page and multiply it by normal resultsPerPage in order to find the first result index
  const current = requestState.current || 1;
  const resultsPerPage = requestState.resultsPerPage || 50;

  // Calculate the index of the first result on the current page
  const firstResultIndex = (current - 1) * resultsPerPage;
  const lastIndex = firstResultIndex + numStudies + 1;

  const req: RequestState = {
    ...requestState,
    current: 1,
    resultsPerPage: lastIndex,
  };

  // we trim the query because we only need the study_ids. Filters and search terms still work properly, but we don't need to calculate anything else
  const qc: any = {
    ...queryConfig,
    result_fields: {
      study_id: {
        raw: {},
        snippet: {size: 200, fallback: true},
      },
    },
    groupPatientIDs: config.searchQuery.groupPatientIDs,
    highlight: {},
  };

  return config.apiConnector.onSearch(req, qc).then(response => {
    let studyIDsBeforeCurrentPage = [];
    const studyIDsAfterCurrentPage = response.results
      .map(result => result.study_id.raw)
      .slice(firstResultIndex, firstResultIndex + numStudies);
    // if the number of studies is greater than the number of studies after the current page, we need to get the studies before the current page
    if (numStudies > studyIDsAfterCurrentPage.length) {
      const numNeeded = numStudies - studyIDsAfterCurrentPage.length;
      studyIDsBeforeCurrentPage = response.results
        .map(result => result.study_id.raw)
        .slice(firstResultIndex - numNeeded, firstResultIndex);
    }
    return [...studyIDsBeforeCurrentPage, ...studyIDsAfterCurrentPage];
  });
};

export type SortDirection = 'asc' | 'desc';

export type SortOption = {
  field: string;
  direction: SortDirection;
};
