import {useQuery, useQueryClient, useMutation} from 'react-query';
import {toast} from 'react-toastify';
import {MenuDropdownSingle} from '../../core/components/menu';
import {ToastMessage} from '../../core/components/toast';
import {
  ClassificationElement,
  fetchModalitiesAndBodyparts,
  updateClassification,
} from '../../models/report';
import {useAxios} from 'src/utils/http';

export const ClassificationDropdown = ({
  studyId,
  bodyPartLLM,
  modalityLLM,
  disabled = false,
}: {
  studyId: string;
  modalityLLM: string[];
  bodyPartLLM: string[];
  disabled?: boolean;
}) => {
  const http = useAxios();
  const {data: classification} = useQuery(
    ['internalGetClassificationData', bodyPartLLM, modalityLLM],
    () => {
      return fetchModalitiesAndBodyparts(
        http,
        bodyPartLLM.map(bp => bp.toUpperCase()),
        modalityLLM.map(mod => mod.toUpperCase())
      );
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const queryClient = useQueryClient();
  const handleUpdateSuccess = () => {
    toast(<ToastMessage title={'Update Successful'} icon="success" />);
    queryClient.invalidateQueries(['report']);
  };

  const handleUpdateError = (err: any) => {
    const message =
      err?.response?.data?.message ?? 'Error updating classification';
    toast(<ToastMessage title={message} icon="error" />);
  };

  const updateClassificationValue = useMutation(
    ({studyId, type, value}: {studyId: string; type: string; value: string}) =>
      updateClassification(http, studyId, type, value),
    {
      onSuccess: () => {
        handleUpdateSuccess();
        queryClient.invalidateQueries('classification');
      },
      onError: error => {
        handleUpdateError(error);
      },
    }
  );

  const handleModalitySelect = (modality: ClassificationElement) => {
    updateClassificationValue.mutate({
      studyId,
      type: 'modality_llm',
      value: modality.code,
    });
  };

  const handleBodyPartSelect = (bodyPart: ClassificationElement) => {
    updateClassificationValue.mutate({
      studyId,
      type: 'body_part_llm',
      value: bodyPart.code,
    });
  };

  const handleProcedureDescriptionSelect = (procedureDescription: string) => {
    updateClassificationValue.mutate({
      studyId,
      type: 'study_description_llm',
      value: procedureDescription,
    });
  };
  return (
    <>
      <hr className="border border-gray-200 my-5" />
      <div>
        <div className="text-gray-400 text-xs font-medium">
          Current Modality: {modalityLLM.join(', ')}
        </div>
      </div>
      <div>
        <div className="text-gray-400 text-xs font-medium">
          Current Body Part: {bodyPartLLM.join(', ')}
        </div>
      </div>
      <hr className="border border-gray-200 my-5" />
      <div>
        <MenuDropdownSingle
          buttonText="Fix the Modality"
          menuItems={[
            ...(classification?.modalities.length
              ? classification.modalities
                  .filter(ccc => {
                    return !modalityLLM.includes(ccc.code);
                  })
                  .map(ccc => {
                    return {
                      itemText: () => <h1>{ccc.label}</h1>,
                      disabled: disabled,
                      onClick: () => handleModalitySelect(ccc),
                    };
                  })
              : [
                  {
                    itemText: () => 'No available modalities',
                    disabled: true,
                  },
                ]),
          ]}
        />
      </div>
      <hr className="border border-gray-200 my-5" />
      <div>
        <MenuDropdownSingle
          buttonText="Fix the Body Part"
          menuItems={[
            ...(classification?.body_parts.length
              ? classification.body_parts
                  .filter(ccc => {
                    return !bodyPartLLM.includes(ccc.code);
                  })
                  .map(ccc => {
                    return {
                      itemText: () => <h1>{ccc.label}</h1>,
                      disabled: disabled,
                      onClick: () => handleBodyPartSelect(ccc),
                    };
                  })
              : [
                  {
                    itemText: () => 'No available body parts',
                    disabled: true,
                  },
                ]),
          ]}
        />
      </div>

      <hr className="border border-gray-200 my-5" />
      <div>
        <MenuDropdownSingle
          buttonText="Fix the Study Description"
          menuItems={[
            ...(classification &&
            classification.study_descriptions &&
            classification.study_descriptions.length
              ? classification.study_descriptions.map(desc => {
                  return {
                    itemText: () => <h1>{desc.StudyDescription}</h1>,
                    disabled: disabled,
                    onClick: () =>
                      handleProcedureDescriptionSelect(desc.StudyDescription),
                  };
                })
              : [
                  {
                    itemText: () => 'No available study descriptions',
                    disabled: true,
                  },
                ]),
          ]}
        />
      </div>
    </>
  );
};
