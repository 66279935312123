import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {trackEvent} from '../../utils/tracking';
import {SavedSearches} from '../../models/search';
import {Card} from '../../core/layout/card';
import ReactTooltip from 'react-tooltip';
import {HiHeart} from 'react-icons/hi';
import {removeAggsField} from '../../models/search';

const REPORT_NO_NEGATIONS = 'report_no_negations';
// Function to extract filters from the Elasticsearch query
function extractFilters(jsonObj: any) {
  try {
    const filters = [];
    if (
      jsonObj.post_filter &&
      jsonObj.post_filter.bool &&
      jsonObj.post_filter.bool.must
    ) {
      for (const mustClause of jsonObj.post_filter.bool.must) {
        if (mustClause.bool && mustClause.bool.should) {
          const filter = mustClause.bool.should[0].term;
          let filterName = String(Object.keys(filter)[0]);
          // eslint-disable-next-line
          const filterValue = filter[filterName];
          filterName = filterName.replace('.keyword', '');
          filters.push(`${filterName}: ${filterValue}`);
        }
      }
    }
    // age filter with query
    // "query": {"bool": {"must": [{"bool": {"filter": [{"bool": {"filter": [{"range": {"age_num": {"gte": 20, "lte": 50}}}]}}]}},
    if (
      jsonObj.query &&
      jsonObj.query.bool &&
      Array.isArray(jsonObj.query.bool.must) &&
      jsonObj.query.bool.must[0].bool &&
      jsonObj.query.bool.must[0].bool.filter &&
      jsonObj.query.bool.must[0].bool.filter[0].bool &&
      jsonObj.query.bool.must[0].bool.filter[0].bool.filter
    ) {
      for (const mustClause of jsonObj.query.bool.must[0].bool.filter[0].bool
        .filter) {
        if (mustClause.range && mustClause.range.age_num) {
          const ageRange = mustClause.range.age_num;
          const gte = ageRange.gte !== undefined ? ageRange.gte : 'any';
          const lte = ageRange.lte !== undefined ? ageRange.lte : 'any';
          filters.push(`Age: ${gte} to ${lte}`);
        }
      }
    }
    // age filter without query
    // "query": {"bool": {"filter": [{"bool": {"filter": [{"range": {"age_num": {"gte": 22, "lte": 24}}}]}}]}},
    if (
      jsonObj.query &&
      jsonObj.query.bool &&
      jsonObj.query.bool.filter &&
      jsonObj.query.bool.filter[0].bool &&
      jsonObj.query.bool.filter[0].bool.filter
    ) {
      for (const mustClause of jsonObj.query.bool.filter[0].bool.filter) {
        if (mustClause.range && mustClause.range.age_num) {
          const ageRange = mustClause.range.age_num;
          const gte = ageRange.gte !== undefined ? ageRange.gte : 'any';
          const lte = ageRange.lte !== undefined ? ageRange.lte : 'any';
          filters.push(`Age: ${gte} to ${lte}`);
        }
      }
    }

    return filters.join(', ');
  } catch (error) {
    console.error('Error parsing Elasticsearch query', error);
    return '';
  }
}

export const processElasticSearchBody = (
  elasticSearchBody: any
): [Set<string>, string, string, string, 'Report' | 'Impression'] => {
  const uniqueQueries = new Set<string>();
  const crossFieldsQueries = new Set<string>();
  let filterList = '';
  let operator = ', ';
  const reportType: {value: 'Report' | 'Impression'} = {value: 'Report'};
  const operation = {value: 'contains substring'};
  const isSynonym = {value: false};

  const elasticSearchBodyObj = removeAggsField(elasticSearchBody) as any;

  const addQuery = (
    query: string | undefined,
    type: string | undefined,
    fields: string[] | undefined
  ) => {
    if (query) {
      if (type === 'best_fields') {
        crossFieldsQueries.add(query);
      } else {
        uniqueQueries.add(query);
      }
    }
    if (fields?.length && fields[0] === REPORT_NO_NEGATIONS) {
      operation.value = 'contains substring (no negations)';
    }
  };

  const traverse = (node: any) => {
    if (Array.isArray(node)) {
      node.forEach(traverse);
    } else if (typeof node === 'object' && node !== null) {
      if (node.multi_match) {
        addQuery(
          node.multi_match.query,
          node.multi_match.type,
          node.multi_match.fields
        );
      } else if (node.clauses) {
        if (Array.isArray(node.clauses)) {
          if (node.clauses.length > 1) {
            reportType.value = 'Impression';
            uniqueQueries.add(`${node.clauses[1].span_term?.report}`);
          }
        }
      } else if (node.match_phrase) {
        const exactMatch =
          node.match_phrase?.report?.query || node.match_phrase?.report;
        if (exactMatch) {
          isSynonym.value = true;
          uniqueQueries.add(`"${exactMatch}"`);
        }
      } else {
        Object.values(node).forEach(traverse);
      }
    }
  };

  try {
    filterList = extractFilters(elasticSearchBody);
    traverse(elasticSearchBodyObj.query);
    operator = ' AND ';
  } catch (error) {
    traverse(elasticSearchBodyObj?.query?.bool?.must || []);
  }

  // If isSynonym then join uniqueQueries with | operator
  if (isSynonym.value) {
    operator = ' | ';
    const synonymQueries = Array.from(uniqueQueries).join(operator);
    uniqueQueries.clear();
    uniqueQueries.add(synonymQueries);
  }

  return [
    uniqueQueries,
    filterList,
    operator,
    operation.value,
    reportType.value,
  ];
};

export const SaveSearchCard = ({
  savedSearch,
  action,
}: {
  savedSearch: SavedSearches;
  action?: (actionType: 'edit' | 'delete') => void;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [savedSearch]);

  // eslint-disable-next-line
  const [newUniqueQueries, filterList, operator, reportType, operation] =
    processElasticSearchBody(savedSearch.elasticSearchBody);

  const queryContent = Array.from(newUniqueQueries).join(operator);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('en-US').replace(',', '');
  };

  const urlObject = new URL(savedSearch.url);
  const relativeUrl =
    urlObject.pathname + urlObject.search + '&autosubmit=true';

  const handleOpenSearch = () => {
    trackEvent('CLICK_SAVED_SEARCH_CARD');
    navigate(relativeUrl, {
      state: {elasticSearchBody: savedSearch.elasticSearchBody},
    });
  };

  return (
    <>
      <Card data-cy="datasetCard">
        <div className="text-gray-400 flex justify-between items-center mb-6 pb-6 border-b">
          <div>
            <div className="flex text-gray-900 items-center">
              <div>Keyword: {queryContent}</div>
            </div>
            {filterList && (
              <div className="mt-4">
                <strong>Filters:</strong> {filterList}
              </div>
            )}
          </div>
          <div>
            {formatDate(savedSearch.createdAt)}
            <div className="inline ml-4">
              <button
                className="btn btn-link inline-block px-0 py-0"
                data-tip="Delete saved searches"
                data-event="mouseenter"
                data-event-off="mouseleave click"
                onClick={() => {
                  trackEvent('CLICK_DELETE_SAVED_SEARCHES_BTN', {
                    ssId: savedSearch.ssId,
                  });
                  action && action('delete');
                }}
              >
                <HiHeart className="w-5 h-5 inline" />
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <div>
              <div
                className="text-2xl font-extrabold"
                data-cy="datasetStudyCount"
              >
                {savedSearch.numberOfResultsSaved.toLocaleString('en-US')}
              </div>
              <div className="text-gray-500">Total studies results</div>
            </div>
          </div>
          <div>
            <button
              onClick={handleOpenSearch}
              className="btn btn-primary max-w-[40%] float-right"
            >
              View Search results
            </button>
          </div>
        </div>
      </Card>
    </>
  );
};
