import {useMemo, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Column, Row} from 'react-table';
import ReactTooltip from 'react-tooltip';
import {useQuery} from 'react-query';
import _ from 'lodash';

import {
  TextFilter,
  ReactTable,
  MinMaxFilter,
} from '../../../core/components/react-table';
import {DicomocrResult} from '../../../models/evalon';
import {DicomInfo, getAllDicomSeries} from '../../../models/dicom-viewer';
import {fetchDICOMs, addLineBreaks} from '../../../models/dicom';
import {Badge} from '../../../core/components/badge';
import {useAxios} from 'src/utils/http';

export const DicomocrResultsTable = ({
  dicomocrResults,
  datasetDicomInfo,
  datasetDicomInfoIsError,
  datasetID,
  selected = [],
  onSelect,
}: {
  dicomocrResults: DicomocrResult[];
  datasetDicomInfo?: DicomInfo;
  datasetDicomInfoIsError?: boolean;
  datasetID: number;
  selected: string[];
  onSelect: (sopIds: string[], checked: boolean) => void;
}) => {
  const api = useAxios();
  const [pageSOPIDs, pageSOPIDsChange] = useState<string[]>([]);

  const allDicomSeries = useMemo(
    () => getAllDicomSeries(datasetDicomInfo),
    [datasetDicomInfo]
  );
  const datasetDicomInfoIsErrorMemo = useMemo(
    () => datasetDicomInfoIsError,
    [datasetDicomInfoIsError]
  );

  const {data: dicoms, isLoading: dicomsIsLoading} = useQuery(
    [datasetID, 'dicomocrReview', pageSOPIDs],
    () => {
      return fetchDICOMs(api, pageSOPIDs);
    },
    {
      enabled: !_.isNil(datasetID) && !_.isEmpty(pageSOPIDs),
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000, // 1 hour
    }
  );

  const columns = useMemo<Array<Column<DicomocrResult>>>(
    () => [
      {
        id: 'selected',
        width: 65,
        Header: props => {
          const {filteredRows} = props;
          const selectedState = _.every(filteredRows, row =>
            _.includes(selected, row.original.sopID)
          )
            ? 'all'
            : _.some(filteredRows, row =>
                _.includes(selected, row.original.sopID)
              )
            ? 'indeterminate'
            : 'none';
          return (
            <div className="inline">
              <input
                type="checkbox"
                className="checkbox-input"
                checked={_.includes(['all', 'indeterminate'], selectedState)}
                ref={el =>
                  el && (el.indeterminate = selectedState === 'indeterminate')
                }
                onChange={e =>
                  onSelect(
                    _.map(filteredRows, row => row.original.sopID ?? ''),
                    e.target.checked
                  )
                }
              />
            </div>
          );
        },
        accessor: row => {
          return _.includes(selected, row.sopID);
        },
        Cell: ({row}: {value: boolean; row: Row<DicomocrResult>}) => {
          const sopID = row.original.sopID ?? '';
          const included = _.includes(selected, sopID);
          return (
            <>
              <input
                type="checkbox"
                className="checkbox-input"
                checked={included}
                onChange={e => onSelect([sopID], e.target.checked)}
              />
            </>
          );
        },
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: 'blackout_status',
        width: 65,
        Header: 'Blackout Status',
        accessor: () => true,
        Cell: ({row}: {value: boolean; row: Row<DicomocrResult>}) => {
          const status = row.original.blackoutStatus ?? '';
          if (status === '') {
            return <Badge type={'info'}>Unsaved</Badge>;
          }
          return <Badge type={'success'}>{status}</Badge>;
        },
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: 'status',
        width: 65,
        Header: 'Status',
        accessor: () => true,
        Cell: ({row}: {value: boolean; row: Row<DicomocrResult>}) => {
          const sopID = row.original.sopID ?? '';
          const image = dicoms?.find(dicom => dicom.sopID === sopID);
          if (dicomsIsLoading) {
            return <Badge type={'info'}>Loading...</Badge>;
          } else if (_.isNil(image)) {
            return <Badge type={'warning'}>Error Loading</Badge>;
          } else if (image.phiHas) {
            return <Badge type={'danger'}>PHI</Badge>;
          } else {
            return image.phiNeedsReview ? (
              <Badge type={'info'}>Not Reviewed</Badge>
            ) : (
              <Badge type={'success'}>Reviewed</Badge>
            );
          }
        },
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Study Info',
        columns: [
          {
            Header: 'Study ID',
            accessor: 'studyID',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'Series ID',
            accessor: row => _.find(dicoms, {sopID: row.sopID})?.seriesID,
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'SOP ID',
            accessor: 'sopID',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            id: 'view',
            width: 65,
            Header: 'View DICOMs',
            accessor: row => {
              return _.includes(selected, row.sopID);
            },
            Cell: ({row}: {value: boolean; row: Row<DicomocrResult>}) => {
              if (datasetDicomInfoIsErrorMemo) {
                return <Badge type="danger">Error</Badge>;
              } else if (allDicomSeries === undefined) {
                return 'Loading..';
              }

              const studyID = row.original.studyID;
              const sopID = row.original.sopID;

              const dicom = dicoms?.find(d => d.sopID === sopID);
              const seriesID = dicom?.seriesID;
              const dicomsCanBeViewed = !_.isNil(dicom);

              return (
                <Link
                  to={`/admin/datasets/review/${datasetID}/dicomViewer/${studyID}/${seriesID}/${sopID}`}
                >
                  <span
                    data-tip={
                      !dicomsCanBeViewed
                        ? 'DICOMs are not available to view for this Sop ID.'
                        : undefined
                    }
                  >
                    <button
                      className="btn btn-xs btn-primary -my-2"
                      disabled={!dicomsCanBeViewed}
                    >
                      View DICOM
                    </button>
                  </span>
                </Link>
              );
            },
            disableSortBy: true,
            disableFilters: true,
          },
        ],
      },
      {
        Header: 'Dicomocr Result',
        columns: [
          {
            Header: '# of Characters',
            id: 'charCount',
            accessor: row => row.detectedText.length,
            Filter: MinMaxFilter,
            filter: 'between',
            disableSortBy: false,
          },
          {
            Header: 'Detected Text - PHI',
            id: 'detectedTextPHI',
            accessor: 'detectedTextPHI',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({row}: {row: Row<DicomocrResult>}) => {
              const text = row.original.detectedTextPHI;
              return <pre>{addLineBreaks(text)}</pre>;
            },
          },
          {
            Header: 'Detected Text',
            id: 'detectedText',
            accessor: 'detectedText',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({row}: {row: Row<DicomocrResult>}) => {
              const text = row.original.detectedText;
              return <pre>{addLineBreaks(text)}</pre>;
            },
          },
          {
            Header: 'Max Confidence',
            accessor: 'phiMaxConfidence',
            Filter: TextFilter,
            filter: 'inclues',
          },
        ],
      },
    ],
    [
      selected,
      onSelect,
      dicoms,
      dicomsIsLoading,
      datasetID,
      datasetDicomInfoIsErrorMemo,
      allDicomSeries,
    ]
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [columns]);

  const data = useMemo(() => dicomocrResults, [dicomocrResults]);
  const renderShowCount = (
    pageSize: number,
    pageIndex: number,
    rowsLength: number
  ) => {
    return (
      <div className="mt-4 mb-2 text-xl flex items-center">
        DICOMocr Results{' '}
        <div className="ml-2 text-gray-500 text-base">
          (Showing {(pageSize * pageIndex + 1).toLocaleString('en-US')}-
          {Math.min(pageSize * (pageIndex + 1), rowsLength).toLocaleString(
            'en-US'
          )}{' '}
          of {rowsLength.toLocaleString('en-US')} DICOMs)
        </div>
      </div>
    );
  };

  return dicomocrResults.length > 0 ? (
    <ReactTable
      tableColumns={columns}
      tableData={data}
      renderCustomShownCount={renderShowCount}
      defaultSortBy={[
        {id: 'evalName', desc: false},
        {id: 'detectedText', desc: true},
      ]}
      onPageChange={page => {
        const newPageSOPIDs = page.map(row => row.original.sopID);
        if (!_.isEqual(pageSOPIDs, newPageSOPIDs))
          pageSOPIDsChange(newPageSOPIDs);
      }}
    />
  ) : (
    <div className="text-xl mt-4 mb-2">No Dicomocr Results</div>
  );
};
